<template>
    <div id="new-client">

      <v-container class="">
        <v-form ref="form" v-model="isFormValid">
          <v-card :loading="isLoading">
            <v-card-text>
              <v-card flat>
                <v-card-title>クライアント情報</v-card-title>
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" sm="6"><v-text-field class="required" label="会社名" v-model="company.end_company_name" :rules="[rules.notEmpty]" ></v-text-field></v-col>
                    <v-col cols="12" sm="6"><v-text-field class="required" label="会社名(カナ)" v-model="company.end_company_kana" :rules="[rules.notEmpty]" ></v-text-field></v-col>
                    <v-col cols="12" sm="3"><v-text-field class="required" label="郵便番号" prefix="〒" v-model="company.zip_code" :rules="[rules.notEmpty]" ></v-text-field></v-col>
                    <v-col cols="12" sm="9"><v-text-field class="required" label="住所" v-model="company.address" :rules="[rules.notEmpty]" ></v-text-field></v-col>
                    <v-col cols="12" sm="6"><v-text-field class="" label="電話番号" prepend-icon="mdi-deskphone" v-model="company.phone"></v-text-field></v-col>
                    <v-col cols="12" sm="6"><v-text-field class="" label="FAX" prepend-icon="mdi-fax" v-model="company.fax"></v-text-field></v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-divider />
            <v-card-text>
              <v-card flat>
                <v-card-title>サポート担当</v-card-title>
                <v-card-text>
                <v-row dense>
                  <v-col cols="12">
                    <v-select v-model="form.main_manager" :items="accounts" item-text="name" item-value="id" label="メイン担当者" prepend-icon="mdi-account" :rules="[rules.notEmpty]" return-object class="required"></v-select>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <div class="d-flex">
                      <v-select v-model="form.sub_manager" :items="remainingSubManager" label="サブ担当者" prepend-icon="mdi-account-multiple" multiple chips persistent-hint item-text="name" return-object item-value="id" class="mr-2"></v-select>
                      <popup-hint>クライアントとのやりとりを共有したいアカウントがある場合のみ選択してください。<br>依頼状況やコメントの通知をメール［Cc］でお知らせします。</popup-hint>
                    </div>
                  </v-col>
                </v-row>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-divider />
            <v-card-text>
              <v-card flat>
                <v-card-title>その他の情報</v-card-title>
                <v-card-text>
                  <v-textarea v-model="company.test_environment" label="テストサーバー情報" auto-grow rows="1" prepend-icon="mdi-server-network"></v-textarea>
                  <v-textarea v-model="company.production_environment" label="本番サーバー情報" auto-grow rows="1" prepend-icon="mdi-server-network"></v-textarea>
                  <v-textarea v-model="company.end_user_admin_memo" persistent-hint label="クライアント社内メモ" auto-grow rows="1" prepend-icon="mdi-note-text-outline" hint="クライアントに関する社内メモを登録できます。この内容はクライアントには表示されません。"></v-textarea>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="navigationBack()">キャンセル</v-btn>
                <v-btn v-if="viewMode === 'edit'" left color="primary" @click="updateClient" :disabled="isChange"><v-icon>mdi-check</v-icon>変更を保存</v-btn>
                <v-btn v-promise-btn v-else-if="viewMode === 'new'" left color="primary" @click="addClient" :disabled="!isFormValid"><v-icon>mdi-check</v-icon>登録</v-btn>
                <v-spacer></v-spacer>
                <!-- 確認用 オリジナルダイアログ -->
                <confirm-dialog v-if="viewMode === 'edit'" btnTitle = "削除" @accept="deleteClient">
                  <template v-slot:title>このクライアントを削除しますか？</template>
                  <template v-slot:message>削除されたデータは永久的に削除され、復元することはできません。</template>
                  <template v-slot:activator="{ on }">
                  <v-btn icon @click="on"><v-icon>mdi-delete-forever</v-icon></v-btn>
                  </template>
                </confirm-dialog>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-container>
    </div>
</template>

<script>
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import PopupHint from '../../components/PopupHint.vue'
import axios from '@/plugins/axios'
export default {
  name: 'Client',
  components: { ConfirmDialog, PopupHint },
  data: () => ({
    viewMode: 'new',
    editClientId: '',
    isLoading: false,
    isFormValid: false,
    isSelecting: false,
    defaultButtonText: '変更',
    rules: {
      notEmpty: value => !!value || '必ず入力してください'
    },
    form: {
      main_manager: null,
      sub_manager: []
    },
    company: {},
    accounts: []
  }),
  created: function () {
    this.determineView()
    this.readFormDataFromAPI()
  },
  methods: {
    readFormDataFromAPI: function () {
      axios.get(`${process.env.VUE_APP_TOKOTON_API_URL}/api/end_user/adminContactLogins`)
        .then(res => {
          // console.log(res.data)
          this.accounts = res.data
        }).catch(err => {
          console.log(err)
        })
    },
    readDataFromAPI: function () {
      axios.get(`${process.env.VUE_APP_TOKOTON_API_URL}/api/end_user/${this.editClientId}`)
        .then(res => {
          // console.log(res.data)
          this.company = res.data
          this.form.main_manager = this.company.primary_contact_login
          // secondary contact persons ↓
          for (var i = 1; i <= 25; i++) {
            var num = this.leadingZero(i)
            if (this.company[`secondary_contact_${num}_login`]) {
              if (this.company[`secondary_contact_${num}_login`].photo == null) {
                this.company[`secondary_contact_${num}_login`].photo = { file_url: '' }
              }
              this.form.sub_manager.push(this.company[`secondary_contact_${num}_login`])
            }
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    leadingZero: function (num) {
      var s = num + ''
      while (s.length < 2) s = '0' + s
      return s
    },
    determineView: function () {
      if (this.$route.params.id) {
        this.editClientId = this.$route.params.id
        this.viewMode = 'edit'
        this.readDataFromAPI()
      } else {
        this.viewMode = 'new'
        this.company = {}
        this.form.main_manager = null
        this.form.sub_manager = []
      }
      console.log(this.viewMode)
    },
    navigationBack: function () {
      this.$router.go(-1)
    },
    onButtonClick () {
      this.isSelecting = true
      window.addEventListener('focus', () => {
        this.isSelecting = false
      }, { once: true })

      this.$refs.uploader.click()
    },
    addClient () {
      const _this = this
      if (_this.$refs.form.validate()) {
        console.info('save')
        const formData = new FormData()
        formData.append('_method', 'POST')
        formData.append('end_company_name', _this.company.end_company_name)
        formData.append('end_company_kana', _this.company.end_company_kana)
        formData.append('zip_code', _this.company.zip_code)
        formData.append('address', _this.company.address)
        formData.append('phone', _this.company.phone)
        formData.append('fax', _this.company.fax)
        formData.append('primary_contact_login_id', _this.form.main_manager.id)
        if (_this.form.sub_manager) {
          for (const loginId of _this.form.sub_manager) {
            formData.append('secondary_contact_login_id[]', loginId.id)
          }
        }
        formData.append('test_environment', _this.company.test_environment ? _this.company.test_environment : '')
        formData.append('production_environment', _this.company.production_environment ? _this.company.production_environment : '')
        formData.append('end_user_admin_memo', _this.company.end_user_admin_memo ? _this.company.end_user_admin_memo : '')
        return new Promise(function (resolve, reject) {
          axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/end_user`, formData)
            .then((res) => {
              _this.$store.commit('setFlashSuccess', 'クライアントを保存しました。')
              _this.$router.push({ name: 'admin:client_index', params: { fillter: 'all' } })
              resolve(res)
            })
            .catch(err => {
              _this.$store.commit('setFlashError', _this.createApiValidErrorMessage(err.response))
              reject(err)
            })
        })
      }
    },
    updateClient () {
      const _this = this
      if (_this.$refs.form.validate()) {
        console.log('update')
        const formData = new FormData()
        formData.append('_method', 'PUT')
        formData.append('end_company_name', _this.company.end_company_name)
        formData.append('end_company_kana', _this.company.end_company_kana)
        formData.append('zip_code', _this.company.zip_code)
        formData.append('address', _this.company.address)
        formData.append('phone', _this.company.phone)
        formData.append('fax', _this.company.fax)
        formData.append('primary_contact_login_id', _this.form.main_manager.id)
        if (_this.form.sub_manager) {
          for (const loginId of _this.form.sub_manager) {
            formData.append('secondary_contact_login_id[]', loginId.id)
          }
        }
        formData.append('test_environment', _this.company.test_environment ? _this.company.test_environment : '')
        formData.append('production_environment', _this.company.production_environment ? _this.company.production_environment : '')
        formData.append('end_user_admin_memo', _this.company.end_user_admin_memo ? _this.company.end_user_admin_memo : '')
        // for (var pair of formData.entries()) {
        //   console.log(pair[0] + ', ' + pair[1])
        // }
        axios.post(`${process.env.VUE_APP_TOKOTON_API_URL}/api/end_user/${this.editClientId}`, formData)
          .then(() => {
            _this.$store.commit('setFlashSuccess', 'クライアントを変更しました。')
            _this.$router.push({ name: 'admin:client_show', params: { id: this.editClientId } })
          })
          .catch(err => {
            _this.$store.commit('setFlashError', this.createApiValidErrorMessage(err.response))
          })
      }
    },
    deleteClient () {
      const _this = this
      axios.delete(`${process.env.VUE_APP_TOKOTON_API_URL}/api/end_user/${this.editClientId}`)
        .then(res => {
          _this.$store.commit('setFlashSuccess', 'クライアントを削除しました。')
          _this.$router.push({ name: 'admin:client_index' })
        })
        .catch(err => {
          _this.$store.commit('setFlashError', '削除することができませんでした。')
          console.log(err)
        })
    }
  },
  computed: {
    isChange () {
      return JSON.stringify(this.company) === JSON.stringify(this.defaultData)
    },
    remainingSubManager () {
      return (this.form.main_manager) ? this.accounts.filter(user => user.id !== this.form.main_manager.id) : this.accounts
    }
  },
  watch: {
    $route (to, from) {
      this.determineView()
    },
    'form.main_manager' () {
      this.form.sub_manager = this.form.sub_manager.filter(elem => elem.id !== this.form.main_manager.id)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
